

@media screen and (min-width:1919){
    body{
      
        .threeD_viewport{

            .banner_caption{
                width: max(300px,25%);
               
                h1{
                    font-size: clamp(2rem,1vw,9rem);
                    
                }

                p{
                    font-size: .9rem;
                }
            }
        }

        section{
            
            .rolls_sizes{
                height: 80vh;
                .container{
                    p{
                        font-size: clamp(1rem,1.5vw,1.3rem);
                    }
                }

                .application_content{
                    p{
                        font-size: min(1.5rem,10vw);
                    }

                    ul{
                        li{
                            font-size: 1rem;
                        }
                    }
                }
            }

            .rolls_sizes_2{
                .container{
                   .cash_register_atm{
                    .flex{
                        width: 50%;
                        .content{
                           p{
                            font-size: min(1.3rem,10vw);
                           }
                        }
                    }
                   }
                }

                .sustainability{
                    .content{
                        p{
                            font-size: 1.1rem;
                        }
                    }

                    
                }
            }

            
            
        }

        .about_container{
            .shop{
                margin-top: 200px;
                .container{
                    .sub_content{
                        font-size: 1.1rem;
                    }

                    .custom_rolls{
                        .custom_rolls_content{
                            top: 20%;
                            transform: translate(-7%, -20%);
                            width: 32%;
                            p{
                                font-size: 1.1rem;
                                line-height: 1.7;
                            }
                        }
                    }

                   
                }

                
            }

            .about_content{
                width: 60%;
                .about{
                   
                    p{
                        font-size: 1.1rem;
                    }

                    .second_content{
                        .nth_content{
                            p{
                                font-size: 1.1rem;
                            }
                        }
                    }

                    .flex_box{
                        .second_flex{
                            p{
                                font-size: clamp(1rem, 2vw, 1.7rem);
                            }
                        }
                    }

                    .main_contract{
                        //text-align: center;
                        .contract1{
                            p{
                                font-size: 1.1rem;
                                
                            }
                        }

                        .contract2{
                            min-height: 40vh;
                            background-size: contain;
                        }
                    }
                }
            }

            .contact_flexbox{
                width: 80%;
            }
        }

        h4,h5{
            font-size: 1.5rem;
        }
    }
}

@media screen and (max-width:900px) {
    body{
        nav{
            padding-top: 30px;
            z-index: 999999;
            .navigation{
                .flex1{
                    img{
                        width: 70%;
                    }
                }
                .flex2{
                    .flex{
                        display: none;
                    }

                    .menu{
                        display: block;
                        color: white;
                        font-size: 2rem;
                        position: relative;
                       
                      

                        span{
                            display: block;
                            width: 28px;
                            height: 2px;
                            margin-bottom: 5px;
                            position: relative;
                            background-color: white;
                            border-radius: 3px;
                            transform-origin: 4px 0px;
                            transition:.5s cubic-bezier(0.77,0.2,0.05,1.0);
                            
                        }
                    }
                    
                }
            }
        }

        .radial_gradient{
            background-image: radial-gradient(circle,#3b3b3be8, #1a1a1ac7, #060606);
        }

        .fix_nav{
            position: fixed;
            top: 0;
        }

        .mobile_nav{
            width: 100%;
            height: 100vh;
            background-color: black;
            position:fixed;
            top: 0;
            left: 100%;
            z-index: 99999;
            transition:  .8s cubic-bezier(0.77,0.2,0.05,1.0);

            ul{
                position: absolute;
                top: 50%;
                padding-left: 60px;
                transform: translateY(-50%);
                li{
                    width: 30%;
                    margin-bottom: 15px;
                    list-style-type: none;
                    margin-left: 30px;
                    opacity: 0;
                    a{
                        img{
                            width: 100%;
                        }
                    }
                }

                li:nth-child(1),li:nth-child(2){
                    width: 25%;

                }

                li:nth-child(3){
                    width: 32%;

                }

                li:nth-child(4){
                    width: 40%;
                }
            }

            footer{
                margin: 30px 0;
                margin-top: 40px;
                .main_content{
                    display: flex;
                    flex-direction: column;
                    .flex{
                        width: 60% !important;
                        display: flex;
                        margin-top: 10px;
                    }

                    .flex:nth-child(2),.flex:nth-child(3){
                        color: white;
                        align-items: center;
                        .socials{
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            align-items: center;
                            opacity: 0;

                            .instagram,.facebook,.mail{
                                width: 20%;

                                img{
                                    width: 100%;
                                }
                            }
                        }
                        
                        justify-content: flex-start !important;
                    }

                    .flex:nth-child(2){
                        margin-top: 30px;
                    }

                    .flex:nth-child(1){
                        display: none;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        justify-content: flex-end;
                        

                        .logo{
                            clip-path: circle(42%);
                        }

                    }

                    .location{
                        color: gray;
                        opacity: 0;
                    }

                    
                }
            }
        }

        .mobile_menu{
            left: 0;
            visibility: visible;
            display: block;
            
        }

        .radial_gradient{
            .social_section{
                display: none;
            }
        }

        .whatsapp{
                bottom: 3%;
                transform: translate(-5%,-3%);
                visibility: hidden;
                opacity: 0;
            .img{
                width: 9.5%;
                
            }
        }

        .threeD_viewport{
            position: relative;
            width: 100%;
            //pointer-events: none ;
            height: 100vh;
            .banner_caption{
                width: 80%;
                left: 50%;
                top: 80%;
                transform: translate(-50%,-80%);
                text-align: center;

                h1{
                    line-height: 1.8;
                }

                p{
                    display: none;
                }

                a{
                    button{
                        font-size: 1.5rem;
                        padding: 20px 55px;
                    }
                }
            }

            .banner_content{
                bottom: 3%;
                transform: translate(-50%) translateY(-3%);
                padding-bottom: 10px;
                .instructions{
                    p{
                        opacity: 0;
                    }
                }
            }

            .touch_area{
                width: 100%;
                height: min(350px, 40vh);
                //background-color: red;
                position: absolute;
                bottom: 0;
            }
        }

        section{
            .rolls_sizes{
                height:auto;

                .application_content{
                    width: 85%;
                    top: 5%;
                    right: 0;
                    z-index: 0;
                    margin-right: 30px;

                    h1{
                        font-size: 1.5rem;
                    }
                }
                .vid_container{
                    margin-top: 80px;
                    height: max(995px,100vh);
                    position: relative;
                    top: 0;
                    
                    .container{
                        width: 90%;
                        min-height: min(500px,70vh);
                        position: relative;

                        button{
                            bottom: 25%;
                            font-size: clamp(1.2rem, 1.5vw, 1.2rem);
                            padding: 15px 45px;
                            margin-top: 20px;
                        }

                        p{
                            font-size: clamp(.9rem, 1vw, 1rem);
                            margin-top: 30px;
                        }

                        .rolls{
                            font-size: clamp(1.7rem, 2vw, 2.5rem);
                            line-height: 1.6;
                            
                        }
                    }
                    video{
                        display: none;
                    }

                    .rolls_range_mobile{
                        display: block;
                        text-align: center;
                        
                        .image_holder{
                            width: 100%;
                            img{
                                width: 95%;
                            }
                        }

                        p{
                            color: white;
                            font-size: 1.2rem;
                            margin: 0;
                        }

                        p:nth-child(2){
                            margin-top: 30px;
                        }

                        p:nth-child(3){
                            font-size: .8rem;
                            margin-top: 10px;
                        }
                    }

                    .range_container{
                        display: block;

                        /*********** Baseline, reset styles ***********/
                        input[type="range"] {
                            -webkit-appearance: none;
                            appearance: none;
                            background: transparent;
                            cursor: pointer;
                            width: 100%;
                            margin-top: 50px;
                        }
                        
                        /* Removes default focus */
                        input[type="range"]:focus {
                            outline: none;
                        }
                        
                        /******** Chrome, Safari, Opera and Edge Chromium styles ********/
                        /* slider track */
                        input[type="range"]::-webkit-slider-runnable-track {
                            background-color: #ffffff;
                            border-radius: 0.5rem;
                            height: 0.4rem;
                        }
                        
                        /* slider thumb */
                        input[type="range"]::-webkit-slider-thumb {
                            -webkit-appearance: none; /* Override default look */
                            appearance: none;
                            margin-top: -16px; /* Centers thumb on the track */
                            background-color: #f5f5f5;
                            border-radius: 0.5rem;
                            border: solid 2px blue;
                            height: 2.5rem;
                            width: 1.5rem;
                        }
                        
                        input[type="range"]:focus::-webkit-slider-thumb {
                            outline: 3px solid #f5f5f5;
                            outline-offset: 0.125rem;
                        }
                        
                        /*********** Firefox styles ***********/
                        /* slider track */
                        input[type="range"]::-moz-range-track {
                            background-color: #ffffff;
                            border-radius: 0.5rem;
                            height: 0.5rem;
                        }
                        
                        /* slider thumb */
                        input[type="range"]::-moz-range-thumb {
                            background-color: #f5f5f5;
                            border: none; /*Removes extra border that FF applies*/
                            border-radius: 0.5rem;
                            height: 2.5rem;
                            width: 1.5rem;
                        }
                        
                        input[type="range"]:focus::-moz-range-thumb{
                            outline: 3px solid #f5f5f5;
                            outline-offset: 0.125rem;
                        }

                        p{
                            color: rgb(166, 166, 166);
                            font-size: .9rem;
                            margin-top: 20px;
                        }

                    }

                    .gradient_background{
                        display: none;
                    }
                }
            }

            .rolls_sizes_2{
                padding-top: 0;
                width: 100%;
                 height: min(1500px,205vh);
                .arrow_left,.arrow_right{
                    display: none;
                }

                .container{
                    transform: translateY(15%);
                    .cash_register_atm{
                        flex-direction: column;
                        min-height: auto;
                        margin-top: 30px;

                        .flex:nth-child(1){
                            width: 100%;
                            min-height: auto;
                            margin-left: 0;
                            justify-content: center;

                            .content{
                                width: 85%;
                                p{
                                    font-size: 1rem;
                                }

                                .mobile_content{
                                    display: none;
                                   

                                    h1{
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }

                        .flex:nth-child(2){
                            width: 100%;
                            min-height: auto;
                            margin-top: 30px;
                            overflow: auto;
                            scroll-snap-type: x mandatory;
                            flex-flow: row nowrap;
                            .carousel{
                               height: min(300px,30vh);
                                .atm{
                                    scroll-snap-align: center;
                                    flex: none;
                                    video{
                                        position: relative;
                                    }
                                }
                                .printer,.cash_register{
                                    height: 30vh;
                                    scroll-snap-align: center;
                                    scroll-snap-stop: always;
                                    flex: none;
                                }
                            }
                        }
                    }
                }

                .sustainability{
                   .sustainability_video{
                    display: none;
                   }

                   .content{
                    width: 85%;
                    left:50%;
                    top: 5%;
                    transform:translate(-50%,-5%);

                    p{
                        font-size: .9rem;
                    }
                   }
                }

                footer{
                    margin: 30px 0;
                    margin-top: 50px;
                    .main_content{
                        flex-direction: column;
                        .flex{
                            width: 60% !important;
                            
                            margin-top: 10px;
                        }

                        .flex:nth-child(2),.flex:nth-child(3){
                            justify-content: flex-start !important;
                        }

                        .flex:nth-child(1){
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            justify-content: flex-end;

                            .logo{
                                clip-path: circle(42%);
                            }

                        }

                        
                    }
                }


            }
        }

        //About Page
        .about_container{
            .about_content{
                width: 75%;
                .about{
                    
                    .second_content{
                        flex-direction: column;
                        margin-top: 50px;
                        text-align: center;
                        width: 100%;
                        
                        .content{
                            width: 100%;

                            p{
                                width: 100%;
                            }
                        }

                       
                    }

                    .main_flex{
                        width: 100%;
                        margin: 50px auto;
                        border-top: 1px solid gray ;
                        border-bottom: 1px solid gray ;
                        
                        h1{
                            margin: 0;
                            padding: 20px 0;
                        }
                    }

                    .flex_box{
                        flex-direction: column;

                        .first_flex{
                            width: 100%;
                            min-height: 20vh;
                            background-size: 120%;
                        }

                        .second_flex{
                            width: 100%;
                            
                            h1{
                                text-align: center;
                            }

                            #second_flex_p{
                                text-align: center;
                            }
                            
                        }
                    }

                    .main_contract{
                        flex-direction: column-reverse;
                        margin-top: 0;

                        .contract2{
                            width: 100%;
                            height: 50vh;
                            min-height: auto;
                        }

                        .contract1{
                            width: 100%;
                            text-align: center;
                        }
                    }

                    .second_banner{
                        width: 100%;
                        margin-top: 0;

                        .second_banner_content{
                            .logo_text{
                                flex-direction: column;
                                h1{
                                    margin: auto;
                                }

                                .main_logo{
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    span{
                                        font-size: clamp(3rem, 4vw, 3.5rem);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            footer{
                margin: 30px 0;
                margin-top: 50px;
                .main_content{
                    flex-direction: column;
                    position: relative;
                    .flex{
                        width: 60% !important;
                        
                        margin-top: 10px;
                    }

                    .flex:nth-child(2),.flex:nth-child(3){
                        justify-content: flex-start !important;
                    }

                    .flex:nth-child(1){
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        justify-content: flex-end;

                        .logo{
                            clip-path: circle(42%);
                        }

                    }

                    
                }
            }

            //Shop Page
            .shop{
                .container{
                    .shop_flexbox{
                        flex-direction: column;
                        .img{
                            margin-bottom: 20px;
                        }
                    }

                    .sub_content{
                        font-size: 1rem;
                    }

                    .custom_rolls{
                        margin-top: 10px;
                        .custom_rolls_content{
                            width: 100%;
                            position: relative;
                            transform: translate(0,0);
                            left: auto;
                            top: auto;
                            color: white;
                            margin: 60px 0px;
                            p{
                               line-height: 2;
                                color: white;
                                font-size: 1rem;
                            }

                            h1{
                                margin-bottom: 35px;
                            }
                        }
                    }
                }
            }

            //Contact Page
            .contact_flexbox{
                flex-direction: column;
                align-items: flex-start;
                padding: 10px 0;
                width: 80%;

                .phone_details{
                    margin: 30px 0;
                    .phone_icon{
                        img{
                            width: 90%;
                        }
                    }
                }

                .mail_details{
                    margin-bottom: 30px;
                }
            }

            .contact_flexbox:nth-child(2){
                margin-top: 0;

                .contact_info,.contact_img{
                    width: 100%;
                    padding: 20px 0;
                }
            }

            .contact_details{
                border-top: solid rgb(85, 85, 85) 1px;
               padding-top: 100px !important;
               margin-top: 100px !important; 

                p{
                    font-size: clamp(1.3rem, 2vw, 2.8rem) !important;
                }
            }

            .map{
                margin-top: 20px;
            }

        }
    }
    
}

@media screen and (max-width:900px) and (orientation:landscape) {

    body{
        nav{
            .navigation{
                .flex1{
                    img{
                        width: 50%;
                    }
                }
            }
        }

        .threeD_viewport{
            .banner_caption{
                h1{
                    font-size: clamp(1rem,2.2vw,1.7rem);
                    margin: 0;
                }
            }
        }

        .mobile_nav{
            ul{
                li{
                    a{
                        img{
                            width: 25%;
                        }
                    }
                }
            }
        }

        .mobile_nav{
            footer{
                .main_content{
                    .flex:nth-child(2){
                        .socials{
                            width: 100%;
                            .instagram,.facebook,.mail{
                                img{
                                    width: 80%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile_nav{
            footer{
                .main_content{
                    .flex:nth-child(2){
                        .socials{
                            .instagram,.facebook,.mail{
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            ul{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 2rem,2.2vw,1.7rem;
                .capital{
                    width: 90%;  
               }

               li{
                a{
                    
                    img{
                        width: 40%;
                    }
                }
               }
            }

        }

        .about_container{

            .about_content{
                .about{
                    .main_contract{
                        .contract1{
                            h4{
                                font-size: 13px;
                                width: 70%;
                                margin: auto;
                            }
                        }
                    }

                    .flex_box{
                        .first_flex{
                            height: 100vh;
                        }
                    }
                }
            }
        }

        .about_container{
            .shop{
                .container{
                    .shop_flexbox{
                        flex-direction: row;
                    }
                }
            }
            
            .contact_flexbox{
                flex-direction: row;
                align-items: center;
                    .mail_details{
                        margin-bottom: 0;
                    }
                
            }
        }
        
        section{
            .rolls_sizes{
                    .application_content{
                        display: none;
                    }
                .vid_container{
                    height: max(700px,80vh);
                }
            }
            .rolls_sizes_2{
                height: min(1600px,350vh);
                    .container{
                        .cash_register_atm{
                            .flex:nth-child(2){
                                
                                .carousel{
                                    height: min(300px,75vh);
                                    .atm{
                                        height: max(300px,80vh);
                                    }

                                    .printer {
                                        height: max(300px,85vh);
                                    }

                                    .cash_register{
                                        height: max(300px,85vh); 
                                    }
                                }
                            }
                        }
                    }
                    
                .vid_container{
                    height: max(500px,60vh);
                }
            }
        }

        .arrow_upwards{
            display: none;
        }

            .whatsapp{
                .img{
                    img{
                        width: 50%;
                    }   
                }
            }

           
            
        
    }
    
}
 