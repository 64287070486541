@media screen and (width >= 1919px) {
  body .threeD_viewport .banner_caption {
    width: max(300px, 25%);
  }

  body .threeD_viewport .banner_caption h1 {
    font-size: clamp(2rem, 1vw, 9rem);
  }

  body .threeD_viewport .banner_caption p {
    font-size: .9rem;
  }

  body section .rolls_sizes {
    height: 80vh;
  }

  body section .rolls_sizes .container p {
    font-size: clamp(1rem, 1.5vw, 1.3rem);
  }

  body section .rolls_sizes .application_content p {
    font-size: min(1.5rem, 10vw);
  }

  body section .rolls_sizes .application_content ul li {
    font-size: 1rem;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex {
    width: 50%;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex .content p {
    font-size: min(1.3rem, 10vw);
  }

  body section .rolls_sizes_2 .sustainability .content p {
    font-size: 1.1rem;
  }

  body .about_container .shop {
    margin-top: 200px;
  }

  body .about_container .shop .container .sub_content {
    font-size: 1.1rem;
  }

  body .about_container .shop .container .custom_rolls .custom_rolls_content {
    width: 32%;
    top: 20%;
    transform: translate(-7%, -20%);
  }

  body .about_container .shop .container .custom_rolls .custom_rolls_content p {
    font-size: 1.1rem;
    line-height: 1.7;
  }

  body .about_container .about_content {
    width: 60%;
  }

  body .about_container .about_content .about p, body .about_container .about_content .about .second_content .nth_content p {
    font-size: 1.1rem;
  }

  body .about_container .about_content .about .flex_box .second_flex p {
    font-size: clamp(1rem, 2vw, 1.7rem);
  }

  body .about_container .about_content .about .main_contract .contract1 p {
    font-size: 1.1rem;
  }

  body .about_container .about_content .about .main_contract .contract2 {
    background-size: contain;
    min-height: 40vh;
  }

  body .about_container .contact_flexbox {
    width: 80%;
  }

  body h4, body h5 {
    font-size: 1.5rem;
  }
}

@media screen and (width <= 900px) {
  body nav {
    z-index: 999999;
    padding-top: 30px;
  }

  body nav .navigation .flex1 img {
    width: 70%;
  }

  body nav .navigation .flex2 .flex {
    display: none;
  }

  body nav .navigation .flex2 .menu {
    color: #fff;
    font-size: 2rem;
    display: block;
    position: relative;
  }

  body nav .navigation .flex2 .menu span {
    transform-origin: 4px 0;
    background-color: #fff;
    border-radius: 3px;
    width: 28px;
    height: 2px;
    margin-bottom: 5px;
    transition: all .5s cubic-bezier(.77, .2, .05, 1);
    display: block;
    position: relative;
  }

  body .radial_gradient {
    background-image: radial-gradient(circle, #3b3b3be8, #1a1a1ac7, #060606);
  }

  body .fix_nav {
    position: fixed;
    top: 0;
  }

  body .mobile_nav {
    z-index: 99999;
    background-color: #000;
    width: 100%;
    height: 100vh;
    transition: all .8s cubic-bezier(.77, .2, .05, 1);
    position: fixed;
    top: 0;
    left: 100%;
  }

  body .mobile_nav ul {
    padding-left: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  body .mobile_nav ul li {
    opacity: 0;
    width: 30%;
    margin-bottom: 15px;
    margin-left: 30px;
    list-style-type: none;
  }

  body .mobile_nav ul li a img {
    width: 100%;
  }

  body .mobile_nav ul li:first-child, body .mobile_nav ul li:nth-child(2) {
    width: 25%;
  }

  body .mobile_nav ul li:nth-child(3) {
    width: 32%;
  }

  body .mobile_nav ul li:nth-child(4) {
    width: 40%;
  }

  body .mobile_nav footer {
    margin: 40px 0 30px;
  }

  body .mobile_nav footer .main_content {
    flex-direction: column;
    display: flex;
  }

  body .mobile_nav footer .main_content .flex {
    margin-top: 10px;
    display: flex;
    width: 60% !important;
  }

  body .mobile_nav footer .main_content .flex:nth-child(2), body .mobile_nav footer .main_content .flex:nth-child(3) {
    color: #fff;
    align-items: center;
    justify-content: flex-start !important;
  }

  body .mobile_nav footer .main_content .flex:nth-child(2) .socials, body .mobile_nav footer .main_content .flex:nth-child(3) .socials {
    opacity: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  body .mobile_nav footer .main_content .flex:nth-child(2) .socials .instagram, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .facebook, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .mail, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .instagram, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .facebook, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .mail {
    width: 20%;
  }

  body .mobile_nav footer .main_content .flex:nth-child(2) .socials .instagram img, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .facebook img, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .mail img, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .instagram img, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .facebook img, body .mobile_nav footer .main_content .flex:nth-child(3) .socials .mail img {
    width: 100%;
  }

  body .mobile_nav footer .main_content .flex:nth-child(2) {
    margin-top: 30px;
  }

  body .mobile_nav footer .main_content .flex:first-child {
    justify-content: flex-end;
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  body .mobile_nav footer .main_content .flex:first-child .logo {
    clip-path: circle(42%);
  }

  body .mobile_nav footer .main_content .location {
    color: gray;
    opacity: 0;
  }

  body .mobile_menu {
    visibility: visible;
    display: block;
    left: 0;
  }

  body .radial_gradient .social_section {
    display: none;
  }

  body .whatsapp {
    visibility: hidden;
    opacity: 0;
    bottom: 3%;
    transform: translate(-5%, -3%);
  }

  body .whatsapp .img {
    width: 9.5%;
  }

  body .threeD_viewport {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  body .threeD_viewport .banner_caption {
    text-align: center;
    width: 80%;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
  }

  body .threeD_viewport .banner_caption h1 {
    line-height: 1.8;
  }

  body .threeD_viewport .banner_caption p {
    display: none;
  }

  body .threeD_viewport .banner_caption a button {
    padding: 20px 55px;
    font-size: 1.5rem;
  }

  body .threeD_viewport .banner_content {
    padding-bottom: 10px;
    bottom: 3%;
    transform: translate(-50%)translateY(-3%);
  }

  body .threeD_viewport .banner_content .instructions p {
    opacity: 0;
  }

  body .threeD_viewport .touch_area {
    width: 100%;
    height: min(350px, 40vh);
    position: absolute;
    bottom: 0;
  }

  body section .rolls_sizes {
    height: auto;
  }

  body section .rolls_sizes .application_content {
    z-index: 0;
    width: 85%;
    margin-right: 30px;
    top: 5%;
    right: 0;
  }

  body section .rolls_sizes .application_content h1 {
    font-size: 1.5rem;
  }

  body section .rolls_sizes .vid_container {
    height: max(995px, 100vh);
    margin-top: 80px;
    position: relative;
    top: 0;
  }

  body section .rolls_sizes .vid_container .container {
    width: 90%;
    min-height: min(500px, 70vh);
    position: relative;
  }

  body section .rolls_sizes .vid_container .container button {
    margin-top: 20px;
    padding: 15px 45px;
    font-size: clamp(1.2rem, 1.5vw, 1.2rem);
    bottom: 25%;
  }

  body section .rolls_sizes .vid_container .container p {
    margin-top: 30px;
    font-size: clamp(.9rem, 1vw, 1rem);
  }

  body section .rolls_sizes .vid_container .container .rolls {
    font-size: clamp(1.7rem, 2vw, 2.5rem);
    line-height: 1.6;
  }

  body section .rolls_sizes .vid_container video {
    display: none;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile {
    text-align: center;
    display: block;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile .image_holder {
    width: 100%;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile .image_holder img {
    width: 95%;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile p {
    color: #fff;
    margin: 0;
    font-size: 1.2rem;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile p:nth-child(2) {
    margin-top: 30px;
  }

  body section .rolls_sizes .vid_container .rolls_range_mobile p:nth-child(3) {
    margin-top: 10px;
    font-size: .8rem;
  }

  body section .rolls_sizes .vid_container .range_container {
    display: block;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"] {
    appearance: none;
    cursor: pointer;
    background: none;
    width: 100%;
    margin-top: 50px;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]:focus {
    outline: none;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]::-webkit-slider-runnable-track {
    background-color: #fff;
    border-radius: .5rem;
    height: .4rem;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    background-color: #f5f5f5;
    border: 2px solid #00f;
    border-radius: .5rem;
    width: 1.5rem;
    height: 2.5rem;
    margin-top: -16px;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]:focus::-webkit-slider-thumb {
    outline-offset: .125rem;
    outline: 3px solid #f5f5f5;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]::-moz-range-track {
    background-color: #fff;
    border-radius: .5rem;
    height: .5rem;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]::-moz-range-thumb {
    background-color: #f5f5f5;
    border: none;
    border-radius: .5rem;
    width: 1.5rem;
    height: 2.5rem;
  }

  body section .rolls_sizes .vid_container .range_container input[type="range"]:focus::-moz-range-thumb {
    outline-offset: .125rem;
    outline: 3px solid #f5f5f5;
  }

  body section .rolls_sizes .vid_container .range_container p {
    color: #a6a6a6;
    margin-top: 20px;
    font-size: .9rem;
  }

  body section .rolls_sizes .vid_container .gradient_background {
    display: none;
  }

  body section .rolls_sizes_2 {
    width: 100%;
    height: min(1500px, 205vh);
    padding-top: 0;
  }

  body section .rolls_sizes_2 .arrow_left, body section .rolls_sizes_2 .arrow_right {
    display: none;
  }

  body section .rolls_sizes_2 .container {
    transform: translateY(15%);
  }

  body section .rolls_sizes_2 .container .cash_register_atm {
    flex-direction: column;
    min-height: auto;
    margin-top: 30px;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child {
    justify-content: center;
    width: 100%;
    min-height: auto;
    margin-left: 0;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child .content {
    width: 85%;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child .content p {
    font-size: 1rem;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child .content .mobile_content {
    display: none;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child .content .mobile_content h1 {
    font-size: 1.4rem;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) {
    scroll-snap-type: x mandatory;
    flex-flow: row;
    width: 100%;
    min-height: auto;
    margin-top: 30px;
    overflow: auto;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel {
    height: min(300px, 30vh);
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .atm {
    scroll-snap-align: center;
    flex: none;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .atm video {
    position: relative;
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .printer, body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .cash_register {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    flex: none;
    height: 30vh;
  }

  body section .rolls_sizes_2 .sustainability .sustainability_video {
    display: none;
  }

  body section .rolls_sizes_2 .sustainability .content {
    width: 85%;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
  }

  body section .rolls_sizes_2 .sustainability .content p {
    font-size: .9rem;
  }

  body section .rolls_sizes_2 footer {
    margin: 50px 0 30px;
  }

  body section .rolls_sizes_2 footer .main_content {
    flex-direction: column;
  }

  body section .rolls_sizes_2 footer .main_content .flex {
    margin-top: 10px;
    width: 60% !important;
  }

  body section .rolls_sizes_2 footer .main_content .flex:nth-child(2), body section .rolls_sizes_2 footer .main_content .flex:nth-child(3) {
    justify-content: flex-start !important;
  }

  body section .rolls_sizes_2 footer .main_content .flex:first-child {
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  body section .rolls_sizes_2 footer .main_content .flex:first-child .logo {
    clip-path: circle(42%);
  }

  body .about_container .about_content {
    width: 75%;
  }

  body .about_container .about_content .about .second_content {
    text-align: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
  }

  body .about_container .about_content .about .second_content .content, body .about_container .about_content .about .second_content .content p {
    width: 100%;
  }

  body .about_container .about_content .about .main_flex {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    width: 100%;
    margin: 50px auto;
  }

  body .about_container .about_content .about .main_flex h1 {
    margin: 0;
    padding: 20px 0;
  }

  body .about_container .about_content .about .flex_box {
    flex-direction: column;
  }

  body .about_container .about_content .about .flex_box .first_flex {
    background-size: 120%;
    width: 100%;
    min-height: 20vh;
  }

  body .about_container .about_content .about .flex_box .second_flex {
    width: 100%;
  }

  body .about_container .about_content .about .flex_box .second_flex h1, body .about_container .about_content .about .flex_box .second_flex #second_flex_p {
    text-align: center;
  }

  body .about_container .about_content .about .main_contract {
    flex-direction: column-reverse;
    margin-top: 0;
  }

  body .about_container .about_content .about .main_contract .contract2 {
    width: 100%;
    height: 50vh;
    min-height: auto;
  }

  body .about_container .about_content .about .main_contract .contract1 {
    text-align: center;
    width: 100%;
  }

  body .about_container .about_content .about .second_banner {
    width: 100%;
    margin-top: 0;
  }

  body .about_container .about_content .about .second_banner .second_banner_content .logo_text {
    flex-direction: column;
  }

  body .about_container .about_content .about .second_banner .second_banner_content .logo_text h1 {
    margin: auto;
  }

  body .about_container .about_content .about .second_banner .second_banner_content .logo_text .main_logo {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  body .about_container .about_content .about .second_banner .second_banner_content .logo_text .main_logo span {
    font-size: clamp(3rem, 4vw, 3.5rem);
  }

  body .about_container footer {
    margin: 50px 0 30px;
  }

  body .about_container footer .main_content {
    flex-direction: column;
    position: relative;
  }

  body .about_container footer .main_content .flex {
    margin-top: 10px;
    width: 60% !important;
  }

  body .about_container footer .main_content .flex:nth-child(2), body .about_container footer .main_content .flex:nth-child(3) {
    justify-content: flex-start !important;
  }

  body .about_container footer .main_content .flex:first-child {
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  body .about_container footer .main_content .flex:first-child .logo {
    clip-path: circle(42%);
  }

  body .about_container .shop .container .shop_flexbox {
    flex-direction: column;
  }

  body .about_container .shop .container .shop_flexbox .img {
    margin-bottom: 20px;
  }

  body .about_container .shop .container .sub_content {
    font-size: 1rem;
  }

  body .about_container .shop .container .custom_rolls {
    margin-top: 10px;
  }

  body .about_container .shop .container .custom_rolls .custom_rolls_content {
    color: #fff;
    width: 100%;
    margin: 60px 0;
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
  }

  body .about_container .shop .container .custom_rolls .custom_rolls_content p {
    color: #fff;
    font-size: 1rem;
    line-height: 2;
  }

  body .about_container .shop .container .custom_rolls .custom_rolls_content h1 {
    margin-bottom: 35px;
  }

  body .about_container .contact_flexbox {
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    padding: 10px 0;
  }

  body .about_container .contact_flexbox .phone_details {
    margin: 30px 0;
  }

  body .about_container .contact_flexbox .phone_details .phone_icon img {
    width: 90%;
  }

  body .about_container .contact_flexbox .mail_details {
    margin-bottom: 30px;
  }

  body .about_container .contact_flexbox:nth-child(2) {
    margin-top: 0;
  }

  body .about_container .contact_flexbox:nth-child(2) .contact_info, body .about_container .contact_flexbox:nth-child(2) .contact_img {
    width: 100%;
    padding: 20px 0;
  }

  body .about_container .contact_details {
    border-top: 1px solid #555;
    margin-top: 100px !important;
    padding-top: 100px !important;
  }

  body .about_container .contact_details p {
    font-size: clamp(1.3rem, 2vw, 2.8rem) !important;
  }

  body .about_container .map {
    margin-top: 20px;
  }
}

@media screen and (width <= 900px) and (orientation: landscape) {
  body nav .navigation .flex1 img {
    width: 50%;
  }

  body .threeD_viewport .banner_caption h1 {
    margin: 0;
    font-size: clamp(1rem, 2.2vw, 1.7rem);
  }

  body .mobile_nav footer .main_content .flex:nth-child(2) .socials, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .instagram img, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .facebook img, body .mobile_nav footer .main_content .flex:nth-child(2) .socials .mail img {
    width: 100%;
  }

  body .mobile_nav ul {
    font-size: 2rem, 2.2vw, 1.7rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  body .mobile_nav ul .capital {
    width: 90%;
  }

  body .mobile_nav ul li a img {
    width: 40%;
  }

  body .about_container .about_content .about .main_contract .contract1 h4 {
    width: 70%;
    margin: auto;
    font-size: 13px;
  }

  body .about_container .about_content .about .flex_box .first_flex {
    height: 100vh;
  }

  body .about_container .shop .container .shop_flexbox {
    flex-direction: row;
  }

  body .about_container .contact_flexbox {
    flex-direction: row;
    align-items: center;
  }

  body .about_container .contact_flexbox .mail_details {
    margin-bottom: 0;
  }

  body section .rolls_sizes .application_content {
    display: none;
  }

  body section .rolls_sizes .vid_container {
    height: max(700px, 80vh);
  }

  body section .rolls_sizes_2 {
    height: min(1600px, 350vh);
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel {
    height: min(300px, 75vh);
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .atm {
    height: max(300px, 80vh);
  }

  body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .printer, body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .cash_register {
    height: max(300px, 85vh);
  }

  body section .rolls_sizes_2 .vid_container {
    height: max(500px, 60vh);
  }

  body .arrow_upwards {
    display: none;
  }

  body .whatsapp .img img {
    width: 50%;
  }
}

/*# sourceMappingURL=index.172ea309.css.map */
